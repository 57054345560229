import React, { useEffect, useState } from 'react';
import {BsArrowLeft} from 'react-icons/bs'

export default function GeneratedSequence() {

  const [dummyData, setDummyData] = useState([])
  //console.log(JSON.parse(localStorage.getItem('myData')))
  const [docs, setDocs] = useState([])

  const loadData = () => {
    const temp = JSON.parse(localStorage.getItem('myData'))
    setDummyData(temp)
  }

  const loadDocs = () => {
    const temp = JSON.parse(localStorage.getItem('docs'))
    setDocs(temp)
  }

  const goBack = () => {
    window.location.href = '/generationPage'
  }

  useEffect(() => {
    loadData()
    loadDocs()
  }, [])


  return (
    <div>
      <div className='flex flex-col items-center bg-myPink min-h-screen min-w-screen py-10'>
        <div className='flex flex-row justify-around items-center mb-10 w-full'>
          <BsArrowLeft className='text-myDarkBlack text-2xl md:text-3xl cursor-pointer' onClick={goBack} />
          <h2 className='uppercase tracking-widest text-md md:text-2xl font-extrabold'>
            Yoga sequence
          </h2>
          <div></div>
        </div>

        <div className='flex bg-myWhite text-md md:text-lg flex-col space-y-3 h-auto w-[80vw] p-3 md:p-10 rounded-md'>
          {dummyData?.map((item, index) => (
            <div key={index}>
              <p className='font-bold'>{item.title}</p>
              <div className='flex flex-col justify-start space-y-2 mt-2'>
                {item.content.map((value, subIndex) => (
                  <div key={subIndex} className='flex flex-row items-center leading-tight justify-start space-x-3'>
                    <div className='w-1 h-1 ml-5 bg-myDarkBlack rounded-full'></div>
                    <p className='text-myDarkBlack'>
                      {value}
                    </p>
                  </div>
                ))}
              </div>
              <div className='flex p-6 flex-row items-center justify-start space-x-3'>
                <img src={item.image} alt='yoga pose' className={`w-56`}/>
              </div>
            </div>
          ))}
        </div>
        <div className='flex flex-col items-center mt-10'>
          <h2 className='uppercase tracking-widest text-md md:text-2xl font-extrabold'>
            Source Data from Books
          </h2>
          <div className='flex flex-col bg-myWhite text-md md:text-lg space-y-3 h-auto w-[80vw] p-3 md:p-10 rounded-md'>
            {docs?.map((item, index) => (
              <div key={index}>
                <p className='font-bold'>{item.pageContent}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}





